var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localTableData,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","id":"ProducerCommissionsTable","sort-by":_vm.sort,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sort=$event},"update:sort-by":function($event){_vm.sort=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.effective_date",fn:function(ref){
var item = ref.item;
return [_c('commission-effective-column',{attrs:{"item":item}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('commission-type-column',{attrs:{"item":item}})]}},{key:"item.insured",fn:function(ref){
var item = ref.item;
return [_c('commission-insured-column',{attrs:{"item":item}})]}},{key:"item.carrier",fn:function(ref){
var item = ref.item;
return [_c('commission-carrier-column',{attrs:{"item":item}})]}},{key:"item.ivans_status",fn:function(ref){
var item = ref.item;
return [_c('commission-status-column',{attrs:{"item":item}})]}},{key:"item.agency_commissions",fn:function(ref){
var item = ref.item;
return [_c('commission-agency-commission-column',{attrs:{"item":item}})]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_c('commission-plan-column',{attrs:{"item":item}})]}},{key:"item.agent_pay_rate",fn:function(ref){
var item = ref.item;
return [_c('commission-pay-rate-column',{attrs:{"item":item}})]}},{key:"item.agent_pay",fn:function(ref){
var item = ref.item;
return [_c('commission-agent-pay-column',{attrs:{"item":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }