<template>
  <v-data-table
    :headers="headers"
    :items="localTableData"
    :loading="loading"
    hide-default-footer
    disable-pagination
    id="ProducerCommissionsTable"
    :sort-by.sync='sort'
    :sort-desc.sync='sortDesc'
  >
    <template v-slot:item.effective_date="{ item }">
      <commission-effective-column :item="item" />
    </template>

    <template v-slot:item.type="{ item }">
      <commission-type-column :item="item" />
    </template>

    <template v-slot:item.insured="{ item }">
      <commission-insured-column :item="item" />
    </template>

    <template v-slot:item.carrier="{ item }">
      <commission-carrier-column :item="item" />
    </template>

    <template v-slot:item.ivans_status="{ item }">
      <commission-status-column :item="item" />
    </template>

    <template v-slot:item.agency_commissions="{ item }">
      <commission-agency-commission-column :item="item" />
    </template>

    <template v-slot:item.plan="{ item }">
      <commission-plan-column :item="item" />
    </template>

    <template v-slot:item.agent_pay_rate="{ item }">
      <commission-pay-rate-column :item="item" />
    </template>

    <template v-slot:item.agent_pay="{ item }">
      <commission-agent-pay-column :item="item" />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import CommissionAgencyCommissionColumn from '@/pages/commissions/commission-overview/pages/producer/columns/CommissionAgencyCommissionColumn'
import CommissionAgentPayColumn from '@/pages/commissions/commission-overview/pages/producer/columns/CommissionAgentPayColumn'
import CommissionCarrierColumn from '@/pages/commissions/commission-overview/pages/producer/columns/CommissionCarrierColumn'
import CommissionEffectiveColumn from '@/pages/commissions/commission-overview/pages/producer/columns/CommissionEffectiveColumn'
import CommissionInsuredColumn from '@/pages/commissions/commission-overview/pages/producer/columns/CommissionInsuredColumn'
import CommissionPayRateColumn from '@/pages/commissions/commission-overview/pages/producer/columns/CommissionPayRateColumn'
import CommissionPlanColumn from '@/pages/commissions/commission-overview/pages/producer/columns/CommissionPlanColumn'
import CommissionStatusColumn from '@/pages/commissions/commission-overview/pages/producer/columns/CommissionStatusColumn'
import CommissionTypeColumn from '@/pages/commissions/commission-overview/pages/producer/columns/CommissionTypeColumn'

import {
  mdiAlertCircleOutline,
  mdiCheckCircleOutline,
  mdiProgressCheck,
  mdiProgressClose,
  mdiProgressDownload,
} from '@mdi/js'

export default {
  components: {
    CommissionCarrierColumn,
    CommissionInsuredColumn,
    CommissionEffectiveColumn,
    CommissionAgentPayColumn,
    CommissionAgencyCommissionColumn,
    CommissionPayRateColumn,
    CommissionTypeColumn,
    CommissionStatusColumn,
    CommissionPlanColumn,
  },
  props: ['loading'],
  data() {
    return {
      localTableData: [],
      icons: {
        mdiAlertCircleOutline,
        mdiProgressDownload,
        mdiProgressCheck,
        mdiProgressClose,
        mdiCheckCircleOutline,
      },
      validations: [],
      sort: undefined,
      sortDesc: undefined,
    }
  },
  methods: {
    ...mapActions({
      setStatementSort: 'commissions/producerStatement/setSort',
      setStatementSortDesc: 'commissions/producerStatement/setSortDesc',
      clearStatementSort: 'commissions/producerStatement/clearSort',
    }),
    showPlanSelector(item) {
      if (item.ivans_status === 'pending') {
        //  return false;
      }

      return true
    },
    showEditValueFields(item) {
      /**
       * If it doesn't have a type, don't allow edit. wait till they select a type like NBS, etc
       */
      if (!item.type || !item.type.length) {
        return false
      }

      return true
    },
  },
  mounted() {
    this.localTableData = this.$_.cloneDeep(this.tableData)
    this.clearStatementSort()
  },
  watch: {
    tableData: {
      deep: true,
      handler() {
        this.localTableData = this.$_.cloneDeep(this.tableData)
      },
    },
    sort: {
      handler() {
        this.setStatementSort(this.sort)
      },
      deep: true,
    },
    sortDesc: {
      handler() {
        this.setStatementSortDesc(this.sortDesc)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      tableData: 'commissions/producerStatement/getCommissionsTable',
      commissionSetup: 'commissions/getCommissionSetup',
      currentUser: 'getCurrentUser',
    }),
    headers() {
      let output = []

      output = [
        ...output,
        ...[
          {
            text: 'Effective',
            align: 'start',
            sortable: true,
            value: 'effective_date',
            class: 'columnEffectiveDate',
            cellClass: 'columnEffectiveDate',
          },
          {
            text: 'Type',
            align: 'start',
            sortable: true,
            value: 'type',
            class: 'columnType',
            cellClass: 'columnType',
          },
          {
            text: 'Insured',
            align: 'start',
            sortable: true,
            value: 'insured',
            class: 'columnInsured',
            cellClass: 'columnInsured',
          },
          {
            text: 'Carrier',
            align: 'start',
            sortable: true,
            value: 'carrier',
            class: 'columnCarrier',
            cellClass: 'columnCarrier',
          },
          // {
          //   text: 'Ivans Status',
          //   align: 'start',
          //   sortable: false,
          //   value: 'ivans_status',
          //   class: 'columnStatus',
          //   cellClass: 'columnStatus',
          // },
          {
            text: 'Agency Pay',
            align: 'start',
            sortable: false,
            value: 'agency_commissions',
            class: 'columnAgencyCommission',
            cellClass: 'columnAgencyCommission',
          },
          /* {
            text: 'Commission Plan',
            align: 'start',
            sortable: false,
            value: 'plan',
            class: 'columnPlan',
            cellClass: 'columnPlan',
          },*/
          {
            text: 'Rate',
            align: 'start',
            sortable: false,
            value: 'agent_pay_rate',
            class: 'columnProducerRate',
            cellClass: 'columnProducerRate',
          },
          {
            text: 'Producer Pay',
            align: 'start',
            sortable: false,
            value: 'agent_pay',
            class: 'columnProducerCommission',
            cellClass: 'columnProducerCommission',
          },
        ],
      ]

      return output
    },
  },
}
</script>

<style lang="scss">
#ProducerCommissionsTable {
  padding: 0;

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: rgba(94, 86, 105, 0.87);
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(94, 86, 105, 0.87);
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(94, 86, 105, 0.87);
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(94, 86, 105, 0.87);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(94, 86, 105, 0.87);
  }

  ::placeholder {
    /* Most modern browsers support this now. */
    color: rgba(94, 86, 105, 0.87);
  }

  td {
    padding: 16px 16px 16px 32px;
    border: none;
    background: white;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  th {
    padding: 16px 24px 16px 32px;
    background: #fff !important;
    border: none;

    /* Light/Components/Table Header */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    text-transform: uppercase;

    /* Light/Text/Primary */
    vertical-align: top;
    color: #3a3541;
  }

  th.columnEffectiveDate{
    white-space: nowrap;
  }

  th.columnType{
    white-space: nowrap;
  }

  th.columnLob{
    white-space: nowrap;
  }

  th.columnInsured{
    white-space: nowrap;
  }

  th.columnCarrier{
    white-space: nowrap;
  }

  .columnProducerRate {
    min-width: 20px;
    max-width: 140px;
  }

  .columnType {
    min-width: 20px;
    max-width: 100px;
  }

  .columnInsured {
    min-width: 120px;
  }

  .columnPlan {
    min-width: 120px;
    max-width: 140px;
  }

  .columnProducerCommission {
    min-width: 20px;
    max-width: 130px;
  }

  .columnAgencyCommission {
    min-width: 120px;
    max-width: 120px;
  }
}
</style>
